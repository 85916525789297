import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';
import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';
import AddFormQuestionConfiguration from "./AddFormQuestionConfiguration";
import EditFormQuestionConfiguration from "./EditFormQuestionConfiguration";
import Spinner from '../../common/reusableComponents/Spinner';


let _items: any = [];

export default class FormQuestionConfiguration extends React.Component<any, any> {

    public departmentItems: any = [];
    public worktypeItems: any = [];
    public currentUser: any;
    public columns: any;
    public formDetailColumns: any;


    constructor(props: any) {
        super(props);

        this.departmentItems = [];
        this.worktypeItems = [];
        this.DeleteDialog = this.DeleteDialog.bind(this);

        if (this.props.role !== global.worxOnline_Admin) {
            window.open('#/AccessDenied', '_self');
        }

        this.currentUser = this.props.currentUser;

        this.columns = [
            {
                field: 'id',
                title: 'ID',
                width: 80,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'workorderTypeTitle',
                title: 'Title',
                // width: 160,
                type: 'string',
            },
            {
                field: 'formTypeTitle',
                title: 'Form Type',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'updatedOn',
                title: 'Updated On',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true,
                sortOrder: 'desc'
            },
            {
                field: 'updatedBy',
                title: 'Updated By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdOn',
                title: 'Created On',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdBy',
                title: 'Created By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
        ];
        this.formDetailColumns = [
            {
                field: 'fieldTitle',
                title: 'Caption',
                // width: 280,
                type: 'string',
            },
            {
                field: 'sequence',
                title: 'Sequence #',
                // width: 280,
                type: 'string',
                sortOrder:'asc'
            },
            {
                field: 'defaultValue',
                title: 'Default Value',
                // width: 280,
                type: 'string',
            },
            {
                field: 'isMultiple',
                title: 'Is Multiple',
                // width: 160,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'required',
                title: 'Is Required',
                // width: 160,
                type: 'string',
                allowHiding: true,
            },

        ]
        let showHideColumns = localStorage.getItem("activityQuestionconfigurationColumns");
        let tempColumns;
        if (showHideColumns !== null) {
            tempColumns = JSON.parse(showHideColumns);
        } else {
            tempColumns = this.columns;
            localStorage.setItem("activityQuestionconfigurationColumns", JSON.stringify(this.columns));
        }
        this.state = {
            items: _items,
            columns: tempColumns,
            hideAlertButton: false,
        };
    }

    public componentDidMount() {
        this._getItemData();
    }
    public DeleteItem(id: any) {
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true,
            showDeleteAlert: false,
        });
        utility.genericDeleteAPICallwithParameter(Config.createWorkOrderTypeRelationAPI, `workorderTypeId=${id.workorderTypeId}&formTypeId=${id.formTypeId}`).then((data: any) => {
            this.setState({
                alertMessage: 'Activity Question field has been deleted.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
                loading: false
            });
            this._getItemData()
        }, (err) => {
            // console.log(err);
            this.setState({
                alertMessage: 'Cannot delete Activity Question field . Please try again later.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
            });
        });

    }
    // edit activty modal

    private EditItemModal(item: any, itemMode: any) {
        this.setState({ showEditModal: true, details: item, mode: itemMode });
    }
    private _showModal = (): void => {
        this.setState({ showModal: true, details: null, mode: 'add' });
    }
    public renderNewModal() {
        return (
            <AddFormQuestionConfiguration
                showModal={this.state.showModal}
                onClose={() => this._closeModal()}
                onClick={() => this._getItemData()}
                message={(e: any) => this.message(e)}
                currentUser={this.currentUser}
                tenantConfig={this.props.tenantConfig}
                mode={"Add"}
                details={this.state.details}
                items={this.state.items}
            />
        );
    }
    public renderEditModal() {
        return (
            <EditFormQuestionConfiguration
                showModal={this.state.showEditModal}
                onClose={() => this._closeEditModal()}
                onClick={() => this._getItemData()}
                message={(e: any) => this.message(e)}
                currentUser={this.currentUser}
                tenantConfig={this.props.tenantConfig}
                mode={this.state.mode}
                details={this.state.details}
                items={this.state.items}
            />
        );
    }
    private _closeModal = (): void => {
        this.setState({ showModal: false });
    }
    private _closeEditModal = (): void => {
        this.setState({ showEditModal: false });
    }

    public message(message: any) {
        this.setState({
            alertMessage: message + ' successfully',
            showAlert: true
        });
    }

    private async _getItemData(showOwnLoader?: boolean) {
        try {
            if (showOwnLoader) {
                this.setState({ loading: true });
            }
            const fieldsData: any = await utility.genericGetAPICallResponse(Config.workoderTypeRelationApi);
            if (fieldsData) {
                _items = [];
                _items = fieldsData.map((item: any) => ({
                    workorderTypeTitle: item.workorderTypeTitle,
                    fieldTitle: item.fieldTitle,
                    fieldCaption: item.fieldCaption,
                    formTypeTitle: item.formTypeTitle,
                    fieldIsGlobal: item.fieldIsGlobal,
                    workorderTypeId: item.workorderTypeId,
                    formTypeId: item.formTypeId,
                    workorderTypeFieldRelationsView: item.workorderTypeFieldRelationsView
                }));
                _items = utility._sortItems(_items, 'title', false);
                this.setState({ items: _items, callAfterInit: true });
                if (showOwnLoader) {
                    this.setState({ loading: false });
                }
            }
        } catch (err) {
            console.log(err);
            this.setState({ loading: false });
        }
    }

    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }
    public render(): React.ReactElement<any> {
        return (
            <React.Fragment>
                {this.renderNewModal()}
                {this.state.showEditModal && this.renderEditModal()}
                {this.state.showDeleteAlert && this.DeleteAlert()}
                {this.state.showAlert && this.alert()}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}> Form Question Configuration </span>
                    </div>
                </div>
                <div>

                    {!this.state.loading ?
                        <DataTable
                            style={{ width: '100%' }}
                            filteralbe={true}
                            groupable={true}
                            rows={this.state.items}
                            columns={this.state.columns}
                            fileName={'formQuestionconfiguration'}
                            columnChooser={'formQuestionconfigurationColumns'}
                            selectionMode={false}
                            showHideColumnMenu={true}
                            callAfterInit={this.state.callAfterInit}
                            stopCommsAfterInit={() => this.stopCommsAfterInit()}
                            loading={this.state.loading}
                            allowExportSelectedData={false}
                            showNewModal={() => this._showModal()}
                            deleteItem={(a: any) => this.DeleteDialog(a)}
                            editItem={(a: any) => this.EditItemModal(a, 'edit')}
                            cloneItem={(a: any) => this.EditItemModal(a, 'clone')}
                            showCommandBtn={true}
                            showNewBtn={true}
                            showCloneBtn={true}
                            hideNewBtn={this.state.showModal || this.state.showEditModal || this.props.hideNewBtn}
                            showViewDetailBtn={false}
                            showActivityDetailTable={true}
                            formDetailColumns={this.formDetailColumns}
                        />
                        :
                        <Spinner size='large' label="Loading..." />
                    }

                </div>
            </React.Fragment>
        );
    }
    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                hideAlertButton={this.state.hideAlertButton}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
            />
        );
    }
    public DeleteDialog(item: any) {
        this.setState({
            DeletealertMessage: 'Are you sure you want to delete?',
            showDeleteAlert: true,
            deleteItem: item
        });
    }
    public DeleteAlert() {
        return (
            <DeleteDialog
                showDialog={this.state.showDeleteAlert}
                message={this.state.DeletealertMessage}
                title={"worxOnline"}
                onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
                onOkButton={() => this.DeleteItem(this.state.deleteItem)}
                onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
            />
        );
    }

}
