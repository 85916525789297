import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';

import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import CancelIcon from '@material-ui/icons/Cancel';

import Accordion from 'devextreme-react/accordion';

import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';
import Spinner from '../../common/reusableComponents/Spinner';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import TextAreaInput from '../../common/reusableComponents/TextArea';
import SelectBox from 'devextreme-react/select-box';
import NumberBoxInput from '../../common/reusableComponents/NumberBox';
import capitalize from '@material-ui/core/utils/capitalize';

const Type: any = [
    { id: 'Asset', text: 'Asset' },
    { id: 'Choice', text: 'Choice' },
    { id: 'CheckBox', text: 'CheckBox' },
    { id: 'Date', text: 'Date' },
    { id: 'DateTime', text: 'DateTime' },
    { id: 'Heading', text: 'Heading' },
    { id: 'Number', text: 'Number' },
    { id: 'Radio', text: 'Radio' },
    { id: 'Section', text: 'Section' },
    { id: 'Sectable', text: 'Sectable' },
    { id: 'Text', text: 'Text' },
];
const TriggerItems: any = [
    { id: 'Pre', text: 'Pre' },
    { id: 'Post', text: 'Post' },
    { id: 'Adhoc', text: 'Adhoc' }
];

export default class EditWorkTypeField extends React.Component<any, any> {

    public worktypeItems: any = [];

    constructor(props: any) {
        super(props);

        this.addUpdateItem = this.addUpdateItem.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.selectionChanged = this.selectionChanged.bind(this);
        this.CustomItem = this.CustomItem.bind(this);
        this.CustomTitle = this.CustomTitle.bind(this);
        this.worktypeItems = [];
        let tempTriggerItems = TriggerItems;
        if (this.props.tenantConfig && this.props.tenantConfig.Config) {
            let workOrderStatuses = this.props.tenantConfig.Config.WorkOrderStatuses;

            workOrderStatuses.forEach((s: any) => {
                tempTriggerItems.push({
                    id: s.name,
                    text: s.name
                });
            });
        }
        this.state = {
            active: true,
            TriggerItems: tempTriggerItems,
            allTriggerItems: tempTriggerItems,
            selectedworktypeItems: [],
            workTypeFieldsItems: this.props.items,
            allworkTypeFieldsItems: this.props.items,
        };
    }

    public componentDidMount() {


    }
    public didMount() {
        this._getworkTypeDataAsync();
        if (this.props.details) {
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                workTypeFieldsItems: this.props.items,
                allworkTypeFieldsItems: this.props.items,
                hideAlertButton: false,
                selectedworktypeItems: [],
                mode: this.props.mode
            })
        }
    }
    public populateValue(details: any, mode?: any) {

        let selectedWorkType;
        if (details.worktypes && details.worktypes.length > 0) {
            selectedWorkType = details.worktypes[0];
        }
        this.setState({

            workTypeFieldsItems: this.props.items,
            allworkTypeFieldsItems: this.props.items,
            id: details.id,
            title: details.title ? { id: details.title, text: details.title } : '',
            type: { id: details.type, text: details.type },
            // woworktype: details.worktype,
            // sequenceno: details.sequenceno,
            // trigger: { id: details.trigger, text: details.trigger },
            // parentsequenceno: details.parentsequenceno,
            Controlname: details.Controlname,
            condition: details.condition,
            // isActive: details.active,
            choices: details.choices,
            // extraAttributes: details.extraAttributes,
            selectedworktypeItems: details.worktypes,
            hideAlertButton: false,
            selectedItems: selectedWorkType,
            selectedWorkTypeID: 0,
            mode: mode,

        });
    }
    private _getworkTypeDataAsync() {
        utility.genericGetAPICall(Config.WTlistname).then((data: any) => {
            //console.log(data);
            this.worktypeItems = [];
            let tempWorkTypeId = this.props.details && this.props.details.worktypes;

            for (let r of data) {
                let findWorkTypeId = tempWorkTypeId && tempWorkTypeId.find((wo: any) => wo.Id === r.Id);

                if (!findWorkTypeId) {
                    this.worktypeItems.push({
                        id: r.Id,
                        text: (r.Title + ' - ' + r.Code),
                    });
                }
            }
            // let selectedWorkType = [];
            // if (tempWorkTypeId !== null) {
            //     tempWorkTypeId.forEach((item, index) => {
            //         let tempWorkType = this.worktypeItems.filter(r => r.id === item.Id);
            //         // console.log("tempWorkType", tempWorkType)
            //         tempWorkType.forEach(item => {
            //             selectedWorkType.push(item);
            //         });
            //     })
            // }
            this.setState({
                worktypeItems: this.worktypeItems,
                allworktypeItems: this.worktypeItems,
                // selectedworktypeItems: tempWorkTypeId
                // woworktype: selectedWorkType
            });
        }, (err) => {
            console.log(err);
        });
    }
    public addUpdateItem() {
        //console.log(this.props.currentUser);
        //genericPostAPIcallForAdminList
        let dataToPassToService: any = {};

        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        if (!this.state.type) {
            this.setState({
                alertMessage: 'Please select type',
                showAlert: true
            });
            return;
        }
        // if (!this.state.trigger) {
        //     this.setState({
        //         alertMessage: 'Please enter trigger',
        //         showAlert: true
        //     });
        //     return;
        // }

        if (!this.state.selectedworktypeItems || this.state.selectedworktypeItems.length < 0) {
            this.setState({
                alertMessage: 'Please select Activity',
                showAlert: true
            });
            return;
        }
        if (this.state.selectedworktypeItems) {
            let validate = true;
            this.state.selectedworktypeItems.forEach((item: any) => {

                if (!validate) {
                    return;
                }
                validate = this.checkWorkTypeItemValidation(item);


            });
            if (!validate) {
                this.setState({
                    alertMessage: `Please fill incomplete fields in worktypes`,
                    showAlert: true
                });
                return false;
            }
        }

        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true
        });
        dataToPassToService.Type = this.state.type.id;
        dataToPassToService.WorkTypes = this.state.selectedworktypeItems;
        dataToPassToService.Title = this.state.title.text;
        dataToPassToService.Usercreated = this.props.currentUser.Email;
        dataToPassToService.Userupdated = this.props.currentUser.Email;
        dataToPassToService.Tenantid = Config.tenantId;
        if (this.state.mode === 'add') {
            utility.genericPostAPIcall(Config.workTypeFieldList, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Activity field has been created");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            dataToPassToService.Id = this.state.id;
            utility.genericUpdateAPICall(Config.workTypeFieldList, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Activity field has been updated");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }
        utility.removeItem(Config.workTypeFieldList)
    }

    public clearFilter(clearfield?: any, title?: any) {

        this.setState({
            title: title ? { id: title, text: title } : '',
            type: '',
            woworktype: '',
            // Controlname: '',
            // condition: '',
            // sequenceno: '',
            // trigger: '',
            // parentsequenceno: '',
            // extraAttributes: '',
            // active: false
            mode: clearfield ? 'add' : 'edit'
        });
        if (!clearfield)
            this.props.onClose();

    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string, isWorkType?: any) => {
        if (isWorkType) {
            let value: any = event.value;
            if (fieldName === 'Trigger' && event.value) {
                value = event.value.text;
            }

            let selectedWorkType: any = this.state.selectedItems[0];
            let selectedWorkTypeID: any = this.state.selectedworktypeItems.findIndex((wo: any) => wo.Id === selectedWorkType.Id);

            let selectedworktypeItems: any = this.state.selectedworktypeItems;
            selectedWorkType[fieldName] = value;
            selectedworktypeItems[selectedWorkTypeID] = selectedWorkType;
            this.setState({
                selectedItems: [selectedWorkType],
                selectedworktypeItems: selectedworktypeItems
            })
        } else {

            this.setState({ [fieldName]: event.value });

            if (this.state.workTypeFieldsItems.some((wotype: any) => wotype.text.toLowerCase() === event.value.toLowerCase())) {

                this.setState({
                    errorMessage: "Title already exist. Please choose another title"
                });
            } else {
                this.setState({
                    errorMessage: ""
                });
            }

        }
    }
    public handleDropdownChange(e: any, fieldName: string, isWorkType?: any) {
        if (isWorkType) {
            let value: any = e.selectedItem;
            if (fieldName === 'Trigger' && e.selectedItem) {
                value = e.selectedItem.text;
            }

            let selectedWorkType = this.state.selectedItems[0];
            let selectedWorkTypeID = this.state.selectedworktypeItems.findIndex((wo: any) => wo.Id === selectedWorkType.Id);

            let selectedworktypeItems = this.state.selectedworktypeItems;
            selectedWorkType[fieldName] = value;
            selectedworktypeItems[selectedWorkTypeID] = selectedWorkType;

            this.setState({
                selectedItems: [selectedWorkType],
                selectedworktypeItems: selectedworktypeItems
            })
        } else {
            this.setState({ [fieldName]: e.selectedItem });
        }
    }


    public onCustomeValueChange = (e: any) => {

        let item: any = e.text ? { id: e.text, text: e.text } : '';
        let workTypeFieldsItems: any = this.state.workTypeFieldsItems;
        if (workTypeFieldsItems.some((wotype: any) => wotype.text === e.text)) {

            workTypeFieldsItems.push(item);
        }
        this.setState({
            workTypeFieldsItems: workTypeFieldsItems,
            title: e.text ? { id: e.text, text: e.text } : '',
            loadingTitle: true
        });
        setTimeout(() => this.setState({ loadingTitle: false }), 100);
    }

    public _onWorkTypeSelect = (e: any, fieldName: string, options?: any, isWorkTyfeFieldOption?: any) => {

        if (isWorkTyfeFieldOption) {
            this.setState({
                title: e.selectedItem && e.selectedItem.text ? { id: e.selectedItem.text, text: e.selectedItem.text } : e.selectedItem
            });
            if (this.state[options] && (e.selectedItem && this.state[options].some((s: any) => s.text === e.selectedItem.text))) {
                let selectedWTField = this.state[options].find((s: any) => s.text === e.selectedItem.text);

                this.populateValue(selectedWTField, 'edit');
            } else {
                this.clearFilter(true, e.selectedItem && e.selectedItem.text);
            }
        } else {
            let worktypeItems: any = this.state.worktypeItems;
            let selectedworktypeItems: any = this.state.selectedworktypeItems ? this.state.selectedworktypeItems : [];
            if (e.selectedItem) {
                let findIndex: any = worktypeItems.findIndex((wo: any) => wo.id === e.selectedItem.id);

                // worktypeItems.
                worktypeItems.splice(findIndex, 1);
                selectedworktypeItems.push({
                    ExtraAttribute: null,
                    IPSField: null,
                    IPSTable: null,
                    Sequenceno: null,
                    Parentsequenceno: null,
                    Trigger: null,
                    Isactive: true,
                    Controlname: null,
                    Choices: null,
                    Condition: null,
                    Id: e.selectedItem.id,
                    IsRequired: false,
                    Title: e.selectedItem.text,
                });
                this.setState({
                    worktypeItems: worktypeItems,
                    selectedworktypeItems: selectedworktypeItems,
                    loading: true
                });
                if (!this.state.selectedWorkType || selectedworktypeItems.length === 1) {
                    this.setState({
                        selectedItems: selectedworktypeItems[0]
                    });
                }
                setTimeout(() => this.setState({ loading: false }), 500)
            }
            this.setState({ [fieldName]: e.selectedItem })
        }
        // if (options) {
        //     let alloption = `all${options}`;
        //     this.setState({ [options]: this.state[alloption] });
        // }
    }

    public removeWorkType() {
        let worktypeItems: any = this.state.worktypeItems;
        let selectedworktypeItems: Array<[]> = this.state.selectedworktypeItems;

        worktypeItems.push({ id: this.state.removingWorkTypeItem?.Id, text: this.state.removingWorkTypeItem?.Title });

        selectedworktypeItems.splice(this.state.removingWorkTypeIndex, 1);
        this.setState({
            selectedworktypeItems: selectedworktypeItems,
            worktypeItems: worktypeItems,
            DeletealertMessage: '',
            showDeleteAlert: false,
            selectedItems: selectedworktypeItems && selectedworktypeItems.length > 0 ? selectedworktypeItems[0] : [],
            selectedWorkTypeID: 0,
            loading: true
        });
        setTimeout(() => this.setState({ loading: false }), 1000)
    }
    // public _onWorkTypeCick(item, i) {
    //     this.setState({
    //         selectedWorkType: item,
    //         selectedWorkTypeID: i
    //     });
    // }
    public listNoDataRender = (element: React.ReactElement<HTMLDivElement>) => {
        const noData = (
            <span style={{ fontSize: "1em", textAlign: 'center' }}>
                <span className="k-icon k-i-check-circle" style={{ fontSize: "2.5em" }} />
                Available
            </span>
        );

        return React.cloneElement(element, { ...element.props }, noData);
    }
    public checkWorkTypeItemValidation(item: any) {

        let validate = false;
        if (item.IPSTable && item.IPSField && item.Sequenceno && item.Trigger && item.Controlname) {
            validate = true;
        }
        return validate
    }
    public selectionChanged(e: any) {

        let newItems: any = [];
        if (this.state.selectedItems) {
            // newItems.push(this.state.selectedItems)
            // newItems = [...this.state.selectedItems]
            newItems = [this.state.selectedItems]
        }

        e.removedItems.forEach((item: any) => {
            const index = newItems.indexOf(item);
            if (index >= 0) {
                newItems.splice(index, 1);
            }
        });
        if (e.addedItems && e.addedItems.length > 0) {
            newItems = e.addedItems;
        }
        this.setState({
            selectedItems: newItems,
        });
    }

    public render(): React.ReactElement<any> {
        return (
            <div>
                {this.state.showAlert && this.alert()}
                {this.state.showDeleteAlert && this.DeleteAlert()}
                <Popup
                    width={'80vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={`${this.state.mode && capitalize(this.state.mode)} Activity Field ${this.state.id ? "(" + this.state.id + ")" : ''}`}
                    dragEnabled={true}
                    hideOnOutsideClick={false}
                    visible={this.props.showModal}
                    // onContentReady={() => this.didMount()}
                    //closeOnOutsideClick={true}
                    onVisibleChange={() => this.clearFilter(false)}
                    onShowing={() => this.didMount()}

                >
                    <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>

                        <div className='popContentDiv'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>

                                    {!this.state.loadingTitle ?
                                        <SelectBox
                                            stylingMode='underlined'
                                            displayExpr="text"
                                            valueExpr="id"
                                            showClearButton={true}
                                            items={this.state.workTypeFieldsItems}
                                            value={this.state.title}
                                            // selectedItem={this.state.title}
                                            label='Title'
                                            searchEnabled={true}
                                            searchMode={'contains'}
                                            searchExpr={"text"}
                                            // valueChangeEvent="keyup"
                                            labelMode={'floating'}
                                            onSelectionChanged={(e: any) => this._onWorkTypeSelect(e, 'title', 'workTypeFieldsItems', true)}
                                            acceptCustomValue={true}
                                            onCustomItemCreating={(e: any) => this.onCustomeValueChange(e)}
                                            noDataText={"Title is available"}

                                        /> :
                                        <Spinner size="small" />
                                    }

                                </Grid>

                                <Grid item xs={12} sm={6}>

                                    <SelectBoxInput
                                        stylingMode='underlined'
                                        displayExpr="text"
                                        valueExpr="id"
                                        showClearButton={true}
                                        items={Type}
                                        selectedItem={this.state.type}
                                        label='Type'
                                        onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'type')}
                                    // allowCustom={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <div className='workTypeListContainer' >
                                        <h3>WorkType Options</h3>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12}>
                                                {!this.state.loading ?
                                                    <>
                                                        <SelectBoxInput
                                                            stylingMode='underlined'
                                                            displayExpr="text"
                                                            valueExpr="id"
                                                            showClearButton={true}
                                                            items={this.state.worktypeItems}
                                                            selectedItem={''}
                                                            label='Activity'
                                                            onSelectionChanged={(e: any) => this._onWorkTypeSelect(e, 'woworktype', 'worktypeItems')}
                                                        />

                                                        {(this.state.selectedworktypeItems && this.state.selectedworktypeItems.length > 0) &&
                                                            <>
                                                                <Accordion
                                                                    dataSource={this.state.selectedworktypeItems}
                                                                    collapsible={true}
                                                                    multiple={false}
                                                                    animationDuration={300}
                                                                    selectedItems={this.state.selectedItems}
                                                                    onSelectionChanged={this.selectionChanged}
                                                                    itemTitleRender={this.CustomTitle}
                                                                    itemRender={this.CustomItem}
                                                                />
                                                            </>
                                                        }
                                                    </> :
                                                    <Spinner size='large' label="Loading..." />
                                                }

                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </ScrollView>

                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'close',
                            text: 'Cancel',
                            stylingMode: "outlined",
                            onClick: () => this.clearFilter(false),
                        }}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'save',
                            text: 'Save',
                            stylingMode: "contained",
                            onClick: this.addUpdateItem,
                        }}
                    />
                </Popup>
            </div >
        );
    }
    public CustomTitle(data: any, i: any) {

        return (
            <div style={{ width: '100%' }} className='displayFlex'>
                <div className='workTypeListItemTitle'> {data.Title}</div>
                <div className='displayFlex' style={{ float: 'right', marginRight: '25px', width: '47%', flexDirection: 'row', justifyContent: 'end' }}>
                    <div style={{ width: 'fit-content' }} className={this.checkWorkTypeItemValidation(data) ? 'validatedworkTypeListItem' : 'workTypeListItem'}>{this.checkWorkTypeItemValidation(data) ? "Validated" : "Please validate all items"}</div>
                    <div style={{ marginLeft: '5px' }}><CancelIcon onClick={() => this.DeleteDialog(i, data)} /></div>
                </div>
            </div>
        );
    }
    public CustomItem(data: any) {
        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <SelectBoxInput
                            stylingMode='underlined'
                            displayExpr="text"
                            valueExpr="id"
                            showClearButton={true}
                            items={this.state.TriggerItems}
                            selectedItem={data.Trigger ? { id: data.Trigger, text: data.Trigger } : ''}
                            label='Trigger Type *'
                            onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'Trigger', true)}

                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextBoxInput
                            stylingMode='underlined'
                            label="Control Name *"
                            value={data.Controlname ? data.Controlname : ''}
                            onValueChanged={(e: any) => this.handleValueChange(e, 'Controlname', true)}
                            onKeyDown={() => { }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <NumberBoxInput
                            stylingMode='underlined'
                            label="Sequence # *"
                            value={data.Sequenceno ? data.Sequenceno : ''}
                            onValueChanged={(e: any) => this.handleValueChange(e, 'Sequenceno', true)}
                            onKeyDown={() => { }}
                            min={0}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <NumberBoxInput
                            stylingMode='underlined'
                            label="Parent Sequence #"
                            mode='number'
                            value={data.Parentsequenceno ? data.Parentsequenceno : ''}
                            onValueChanged={(e: any) => this.handleValueChange(e, 'Parentsequenceno', true)}
                            onKeyDown={() => { }}
                            min={0}

                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <TextBoxInput
                            stylingMode='underlined'
                            label="IPS Field *"
                            value={data.IPSField ? data.IPSField : ''}
                            onValueChanged={(e: any) => this.handleValueChange(e, 'IPSField', true)}
                            onKeyDown={() => { }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <TextBoxInput
                            stylingMode='underlined'
                            label="IPS Table *"
                            value={data.IPSTable ? data.IPSTable : ''}
                            onValueChanged={(e: any) => this.handleValueChange(e, 'IPSTable', true)}
                            onKeyDown={() => { }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextBoxInput
                            stylingMode='underlined'
                            label="Condition"
                            value={data.Condition ? data.Condition : ''}
                            onValueChanged={(e: any) => this.handleValueChange(e, 'Condition', true)}
                            onKeyDown={() => { }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <br />
                        <CheckBoxInput value={data && data.IsRequired ? true : false} onValueChanged={(e: any) => this.handleValueChange(e, 'IsRequired', true)} text={'Is Required'} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <br />
                        <CheckBoxInput value={data && data.Isactive ? true : false} onValueChanged={(e: any) => this.handleValueChange(e, 'Isactive', true)} text={'Active'} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <TextAreaInput
                            stylingMode='underlined'
                            label="Extra Attribute"
                            value={data.ExtraAttribute ? data.ExtraAttribute : ''}

                            onValueChanged={(e: any) => this.handleValueChange(e, 'ExtraAttribute', true)}

                        />
                    </Grid>
                    {(this.state.type && (this.state.type.id === 'Choice' || this.state.type.id === 'Radio' || this.state.type.id === 'CheckBox')) && (
                        <Grid item xs={12} sm={6}>
                            <TextAreaInput
                                stylingMode='underlined'
                                label="Choices"
                                value={data.Choices ? data.Choices : ''}

                                onValueChanged={(e: any) => this.handleValueChange(e, 'Choices', true)}

                            />
                        </Grid>
                    )}
                </Grid>
            </div>
        )
    }
    public DeleteDialog(i: any, item: any) {
        this.setState({
            DeletealertMessage: 'Are you sure you want to delete?',
            showDeleteAlert: true,
            removingWorkTypeIndex: i,
            removingWorkTypeItem: item,
        });
    }
    public DeleteAlert() {
        return (
            <DeleteDialog
                showDialog={this.state.showDeleteAlert}
                message={this.state.DeletealertMessage}
                title={"worxOnline"}
                onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
                onOkButton={() => this.removeWorkType()}
                onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
            />
        );
    }

}
